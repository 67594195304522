<template>
    <template v-if="departamento">
    <section class="aa-departamento fade-in">
        <div class="aa-departamento-portada">
            <div class="aa-departamento-titulo aa-text-h2-5 mt-1 text-start mx-1 fw-500">
                {{departamento.nombre}}
                <div class="line"></div>
            </div>
            <div class="portada-departamento" :style="'background-image: url('+departamento.portada+')'" >
                <!-- <img :src="departamento.portada" :alt="departamento.nombre" /> -->
            </div>



            <div class="aa-departamento-coordinador text-start ps-1">
                Responsable: {{departamento.responsable}}
            </div>
        </div>
        <div class="aa-departamento-info">
            <div class="info text-start" v-html="departamento.descripcion"></div>
        </div>
        <div class="aa-departamento-extras mb-2">
            
            <DescargaDocumento :documentos="departamento.programacion" :titulo="'Programación'" class="mt-1" />

            <div class="accordion" ref="el">
                <div class="accordion-group mb-2" v-if="departamento.extra">
                    <div class="accordion-menu text-start titulo">
                        Extra <small> Saber +</small>
                    </div>
                    <div class="accordion-content text-start info-departamento" v-html="departamento.extra"></div>
                </div>
            </div>

        </div>
    </section>
    </template>
    <template v-else>
        <DepartamentoSk />
    </template>
</template>

<script>
import { defineAsyncComponent, watch, watchEffect } from '@vue/runtime-core'
import { useRoute } from 'vue-router'

import useDepartamentos from '../composables/useDepartamentos'

export default {
    components: {
        DescargaDocumento: defineAsyncComponent( () => import('../components/Area/DescargaDocumento.vue')),
        DepartamentoSk: defineAsyncComponent( () => import('../components/skeleton/DepartamentoSk.vue')),
    },  
    setup(){
        const route = useRoute()
        const {isLoading, departamento, el,loaded, detalleDepartamento, asignaAnimacion} = useDepartamentos()
        
        detalleDepartamento(route.params.id)

        watch(
            () => route.params.id,
            () => detalleDepartamento(route.params.id)
        )

        watchEffect(() => {
            if (el.value) {
                asignaAnimacion()
            }
        })

        return {
            isLoading, 
            departamento,
            el,
            loaded,
            onLoaded: () => loaded.value = true,
        }
    }
}
</script>

<style lang="sass" scoped>
.aa-departamento
    $root:&
    
    display: flex
    flex-direction: column
    flex-wrap: wrap
    margin-top: 96px
    &-titulo
        order: 1
        .line
            background-color: #86e4b9
            display: inline-block
            height: 20px
            left: 0
            position: absolute
            top: 32px
            width: 90%
            z-index: -1

    &-portada
        display: flex
        flex-direction: column
        .portada-departamento
            order:3
            width: 100%
            margin-bottom: 0px
            background-size: 100%
            background-repeat: no-repeat
            background-position: center
            overflow: hidden
            height: 220px
            @include breakpoint(md)
                height: 400px

    &-coordinador
        order:2
        font-size: 0.8rem
        margin-top: 7px
        margin-bottom: 6px

    &-info
        width: 93%
        font-size: 0.9rem
        background-color: #D8D8D8
        .info
            width: 88%
            background-color: #1AD580
            padding: 1rem 1rem 1rem 1rem
        
        @include breakpoint(md)
            width: 70%
            position: relative
            bottom: 20px
            .info
                width: 94%

.titulo
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-between
    position: relative
    &::after
        content: " "
        position: absolute
        height: 2px
        top: 34px
        width: 80%
        background-image: linear-gradient(45deg, $base-color, transparent)
        left: 0px

</style>