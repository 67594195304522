import { ref } from "vue"
import { gsap } from 'gsap'
import { marked } from "marked"

import publicApi from "@/api/publicApi"

import defaultImg from '@/assets/img/ESCUDO_FRAY.png'

const useDepartamentos = () => {
    const departamento = ref()
    const isLoading = ref(false)
    const el = ref(null)
    const animations = [];
    const loaded = ref(false)
    const detalleDepartamento = async (id ) => {
        try{
            if (!id) return
            isLoading.value = true
            const { data } = await publicApi.get(`departamentos/${id}?filters[area]=1&populate[portada][fields][0]=url&populate[responsable][fields][0]=nombre&populate[programacion][fields][0]=url, caption`)
            isLoading.value = false
            departamento.value =  {
                    id: data.data.id,
                    nombre: data.data.attributes.nombre,
                    descripcion: marked(data.data.attributes.descripcion),
                    programacion: (data.data.attributes.programacion) 
                            ? data.data.attributes.programacion.data
                            : null,
                    extra: (data.data.attributes.extra) ? marked(data.data.attributes.extra) : '',
                    portada: (data.data.attributes.portada.data) ? data.data.attributes.portada.data.attributes.url : defaultImg,
                    responsable: data.data.attributes.responsable.data.attributes.nombre
                }
        }catch(error){
            console.log(error)
        }
    }


    // Animaciones

    const toggleAnimation = (menu) =>  {
        const selectedReversedState = menu.animation.reversed();
        animations.forEach(animation => animation.reverse());
        menu.animation.reversed(!selectedReversedState);
    }

    const createAnimation = (element) => {
        const menu = element.querySelector(".accordion-menu");
        const box  = element.querySelector(".accordion-content");
        gsap.set(box, { height: "auto"})
        
        const tween = gsap.from(box, { 
            height: 0, 
            duration: 0.5, 
            ease: "power1.inOut",
            reversed: true
        });
        
        menu.animation = tween;
        animations.push(tween);
    }

    const asignaAnimacion = () => {
        const groups = gsap.utils.toArray(".accordion-group");
        const menus = gsap.utils.toArray(".accordion-menu");
        groups.forEach(group => createAnimation(group))
            menus.forEach(menu => {
                menu.addEventListener("click", () => toggleAnimation(menu))
            });
    }


    return {
        asignaAnimacion,
        detalleDepartamento,
        departamento,
        isLoading,
        el,
        loaded
    }

}


export default useDepartamentos